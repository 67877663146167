@import '@/styles/placeholders';

.container {
  @include stickContainer;
  .generalResult {
    margin-bottom: 15px;
  }

  .chartResult {
    @include overflowContent;
  }
}