@import '@/styles/variables';
@import '@/styles/functions';

.note {
  font-size: rem(12);
  line-height: rem(15);
  color: $text-yellow;
  margin-top: rem(10);
  user-select: none;
  margin-bottom: 0;
}