@import '@/styles/variables';
@import '@/styles/functions';
@import '@/styles/placeholders';

.container {
  @include stickContainer;

  .content {
    @include overflowContent;
    padding: rem(30) rem(18) rem(18);
  }
}
