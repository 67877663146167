@import '@/styles/variables';
@import '@/styles/functions';

.container {
  padding-top: 30px;

  button {
    margin-bottom: 15px;
  }
}
