.toolbox {
  list-style-type: none;
  margin: 15px 0;
  padding: 0;
  overflow: hidden;

  li {
    float: left;
    margin-left: 10px;

    a {
      color: #005aab;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.buttonLeft {
  display: block !important;
  text-align: right;
}
