@import '@/styles/functions';

.formSection {
  max-width: rem(650);
  margin-bottom: rem(20);

  .content {
    display: flex;
    flex-wrap: wrap;

    .input {
      width: 100%;
      max-width: rem(288);
      margin-bottom: rem(10);
      margin-right: rem(16);
    }
  }
}
