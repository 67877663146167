@import '@/styles/variables';
@import '@/styles/functions';

.container {
  display: flex;
  height: rem(246);

  .warning {
    width: 100%;
    max-width: rem(213);
    background-color: $background-yellow;
    position: relative;

    .icon {
      color: #fff;
      font-size: rem(92);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .content {
    padding: rem(32);
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .description {
      text-align: center;
      font-size: rem(14);
      color: $text-black;
      margin-top: 0;
      user-select: none;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .buttonGroups {
      display: flex;
      flex: none;
      justify-content: center;

      .button {
        width: 100%;
        max-width: rem(98);

        & + .button {
          margin-left: rem(18);
        }
      }
    }
  }
}

@media (max-width: #{$max-sm}) {
  .container {
    .warning {
      display: none;
    }
  }
}
