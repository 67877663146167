@import '@/styles/variables';

.mail_status_list {
    margin-top: 10px;
    overflow: auto;
    max-height: 200px;
}

.mail_status_list ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.mail_status_list ul li {
    margin: 3px 0;
}

.mail_status_export a,
.mail_status_list ul li a {
    color: $text-blue;
}