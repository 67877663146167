@import '@/styles/variables';
@import '@/styles/functions';

.container {
  margin-bottom: rem(15);
  display: flex;
  flex-direction: row;

  .button {
    width: 100%;
    max-width: rem(160);
    margin-right: rem(10);

    & [class^='icon'] {
      font-size: rem(9);
    }

    &.import {
      background-color: $background-green;
    }
  }
}
