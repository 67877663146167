$text-red: #fa4d55;
$text-yellow: #f0c11b;
$text-green: #24a047;
$text-light-blue: #4488ff;
$text-blue: #005aab;
$text-black: #161616;
$text-gray-6e: #6e6e6e;
$text-gray-52: #525252;
$text-gray-89: #898989;
$text-gray-8d: #8d8d8d;
$text-white: #fff;

$background-red: #ed1c24;
$background-red-fa: #fa4d55;
$background-green: #24a047;
$background-light-blue: #4488ff;
$background-dark-blue: #00529D;
$background-blue: #005aab;
$background-gray-e0: #e0e0e0;
$background-gray-f3: #f3f3f3;
$background-gray-f7: #f7f7f7;
$background-gray-fc: #fcfcfc;
$background-gray-89: #898989;
$background-white: #fff;
$background-yellow: #F0C11B;

$border-black: #161616;
$border-green: #24a047;
$border-gray-e0: #e0e0e0;
$border-gray-89: #898989;
$border-gray-70: #707070;
$border-light-gray: #8d8d8d;
$border-blue: #005aab;

$font-semi-bold: 600;
$font-bold: 700;
$font-light: 300;
$font-regular: 400;

$max-xs: 374px;
$min-sm: 375px;
$max-sm: 575px;
$min-md: 576px;
$max-md: 767px;
$min-lg: 768px;
$max-lg: 991px;
$min-xl: 992px;
$max-xl: 1199px;
$min-xxl: 1200px;
$max-xxl: 1599px;
$min-xxxl: 1600px;
