@import '@/styles/functions';
@import '@/styles/variables';

.group {
  display: flex;
  flex-wrap: wrap;
}

.input {
  width: 100%;
  max-width: rem(288);
  margin-bottom: rem(10);
  margin-right: rem(16);
}

%election-unit {
  font-size: rem(13);
  font-weight: $font-bold;
  line-height: rem(17);
  color: $text-gray-52;
}

.electionUnit {
  @extend %election-unit;
}

.electionUnitError {
  @extend %election-unit;

  color: $text-red;
}