@import '@/styles/variables';

.active {
  color: $text-green;
}
.deactivated {
  color: $text-red;
}
.awaiting {
  color: $text-yellow;
}
