@import '@/styles/variables';

.received {
  color: $text-green;
}

.refunded {
  color: $text-yellow;
}

.removed {
  color: $text-red;
}

.dimmedText {
  color: #9a9a9a;
  font-style: italic;
}
