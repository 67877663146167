@import '@/styles/placeholders';


.action {
  @include action;
}

.modal {
  text-align: left;

  > div > div:first-child {
  flex-direction: column;
  }

  .modalTitle {
    margin-bottom: 10px;
  }

  .input {
    max-width: none;
    width: 100%;
  }
}
 