@import '@/styles/variables';
@import '@/styles/functions';

.item {
  border-bottom: 2px solid #ddd;
  padding: 5px 0;

  .breadcrumb {
    margin-bottom: 5px;
  }

  .info {
    font-size: rem(14);
    margin-bottom: 5px;
  }

  a {
    color: $text-blue;
  }

  &:first-child {
    padding-top: 0;
  }

  .controls {
    padding-top: 5px;
    font-size: rem(14);
    display: flex;
    flex-wrap: nowrap;

    .noteContainer {
      width: 500px;
      margin: 0;
      flex-grow: 3;
    }

    .control {
      font-size: rem(16);
      list-style: none;
      padding: 5px;
      margin: 0;
      text-align: right;
      flex-grow: 1;
      .removeControl {
        color: $text-red;
      }
    }
  }

  @media (max-width: #{$max-md}) {
    .controls {
      display: block;

      .noteContainer {
        width: 100%;
      }
    }
  }
}