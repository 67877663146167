@import '@/styles/variables';
@import '@/styles/functions';

.temporaryPasswordInfo {
  font-size: rem(14);
  line-height: rem(18);
  color: $text-black;
  margin-top: 0;
  margin-bottom: rem(8);
}

.action {
  font-size: rem(14);
  line-height: rem(18);
  color: $text-blue;
  margin-top: 0;
  margin-bottom: rem(8);
  cursor: pointer;
  text-decoration: underline;

  &.fetching {
    cursor: progress;
  }
}

.loading {
  width: rem(50);
  height: rem(50);
  margin: 0 auto rem(8);
}
