@import '@/styles/functions';
@import '@/styles/variables';

.iconColumnWrapper {
  position: relative;
  cursor: pointer;

  &:hover > .tooltip {
    display: block;
  }

  .tooltip {
    width: rem(200);
    right: rem(-212);
    height: rem(100);
    overflow: auto;
    border-radius: rem(4);
    margin: 0;
    padding: rem(5);
    border: 1px solid $border-gray-89;
    background-color: $background-gray-e0;
    display: none;
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);

    &:hover {
      display: block;
    }
  }

  .documentGroup {
    margin-top: 0;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    .link {
      color: $text-blue;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
