@import '@/styles/variables';
@import '@/styles/functions';

.select {
  max-width: rem(217);
  margin-bottom: rem(10);
}

.note {
  font-size: rem(12);
  line-height: rem(15);
  color: $text-yellow;
  margin-top: 0;
  user-select: none;
  margin-bottom: rem(18);
}
