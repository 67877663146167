@import '@/styles/variables';
@import '@/styles/functions';

.errorMessage {
  color: #fa4d55;
  font-size: rem(12);
  line-height: rem(15);
  color: $text-red;
  margin-top: rem(10);
  margin-bottom: 0;
  user-select: none;
  text-align: left;
}
