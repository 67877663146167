@import '@/styles/variables';

.container {
  .content {
    max-width: 640px;
    .flexContainer {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $border-gray-e0;

      & > div {
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 10px;
        width: 100px;

        &.heading {
          font-weight: $font-semi-bold;
          text-align: right;
          width: 240px;
          padding-left: 0;
          padding-right: 0;
        }

        &.numeric {
          text-align: right;
        }

        &.percent {
          text-align: right;
        }
      }
    }
  }
}