@import '@/styles/variables';

.contentTooltip {
  min-width: 512px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.content {
  color: $text-blue;
}