@import '@/styles/variables';

.container {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  .logoWrapper {
    flex: 1 1 auto;
    position: relative;
    background-color: $background-gray-fc;

    .logo {
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      user-select: none;
    }
  }

  .content {
    flex: 1 1 auto;
    position: relative;
  }
}

@media (max-width: #{$max-sm}) {
  .logoWrapper {
    display: none;
  }
}
