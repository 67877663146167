@import '@/styles/functions';

.container {
  display: flex;
  flex-wrap: wrap;

  .input {
    width: 100%;
    max-width: rem(190);
    margin-right: rem(10);
  }
}
