@import '@/styles/functions';
@import '@/styles/variables';

.container {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  margin-bottom: 10px;

  .formControl {
    flex-grow: 9;
    margin-right: 10px;
    .input {
      width: 100%;
      height: 100%;
      min-height: 0;
      max-width: none;
      margin-bottom: 0;
  
      textarea {
        width: 100%;
        min-height: 0;
        max-width: none;
        height: 100%;
      }
    }
  }

  .actions {
    flex-grow: 1;
    .button {
      width: 100%;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      &.clearButton {
        background-color: $background-red;
      }
    }
  }
}