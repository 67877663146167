@import '@/styles/variables';
@import '@/styles/functions';
@import '@/styles/placeholders';

.container {
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  font-size: rem(12);

  .content {
    font-weight: bold;
  }

  .name {
    @include memberName;
    display: inline-block;
  }

  .detailButton {
    float: right;
  }
}

.noselect {
  opacity: 0.5;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
