@import '@/styles/variables';
@import '@/styles/functions';

.container {
  padding: rem(18);

  .addButton {
    width: 100%;
    max-width: rem(160);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(34);

    & [class^='icon'] {
      font-size: rem(9);
    }
  }
}
