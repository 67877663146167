@import '@/styles/functions';

.container {
  .input {
    width: 100%;
    min-height: 0;
    max-width: none;
    margin-bottom: 0;

    textarea {
      width: 100%;
      min-height: 0;
      max-width: none;
      height: auto;
    }
  }

  .formLabel {
    width: 95px;
    float: left;
    font-weight: bold;
  }

  .formControl {
    margin-left: 100px;
  }
}