@import '@/styles/variables';
@import '@/styles/functions';

.status {
  font-size: rem(16);
  line-height: rem(20);
  color: $text-green;
  margin-top: 0;
  margin-bottom: rem(10);
}

.actionWrapper {
  font-size: rem(14);
  line-height: rem(18);
  color: $text-blue;
  margin-top: 0;
  margin-bottom: rem(8);
  text-decoration: underline;

  .action {
    cursor: pointer;

    &.fetching {
      cursor: progress;
    }
  }
}

.checkbox {
  margin: 0;

  &.fetching label {
    cursor: progress !important;
  }
}

.loading {
  width: rem(50);
  height: rem(50);
  margin: 0 auto rem(8);
}
