@import '@/styles/placeholders';
@import '@/styles/variables';

.container {
  @include stickContainer;

  .content {
    height: 100%;
    display: flex;

    .leftPanel {
      padding: 10px;
      width: 20%;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      min-width: 200px;
      display: inline-block;
    }

    .rightSection {
      height: 100%;
      width: 80%;
      display: inline-block;
    }
  }
}
