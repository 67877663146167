@import '@/styles/variables';
@import '@/styles/functions';

.container {
  margin-bottom: rem(15);
  .summary {
    display: inline-block;
    padding: 0;
    list-style: none;
    margin: 0;

    li {
      display: inline-block;
      font-weight: $font-semi-bold;

      &:first-child {
        padding-left: 5px;
      }

      &+li:before {
        padding: 5px;
        color: black;
        content: "|";
      }
    }
  }

  .title {
    font-weight: $font-bold;
    text-transform: uppercase;
  }

  .received {
    color: $text-green;
  }

  .refunded {
    color: $text-yellow;
  }

  .removed {
    color: $text-red;
  }
}