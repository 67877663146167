@import '@/styles/variables';
@import '@/styles/functions';

.container {
  font-size: rem(14);
  font-weight: $font-regular;
  padding: 0 rem(18);
  width: 100%;
  max-width: rem(178);
  color: $text-white;
  border: none;
  text-decoration: none;
  cursor: pointer;
  flex: none;
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  text-align: center;
  position: relative;
  white-space: nowrap;
  height: rem(40);
  background-color: $background-blue;
  margin-bottom: rem(16);

  .input {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not(:disabled):hover {
    color: $text-white;
  }

  & [class^='icon'] {
    font-size: rem(9);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
