@import '@/styles/variables';
@import '@/styles/functions';

.container {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  .content {
    padding: 0 rem(15);
    color: $text-black;
    text-align: center;
    user-select: none;

    .title {
      font-weight: $font-light;
      font-size: rem(42);
      text-align: center;
      margin-bottom: rem(31);
      margin-top: 0;
    }

    .description {
      font-size: rem(16);
      margin-bottom: rem(32);
      margin-top: 0;
    }

    .form {
      max-width: rem(288);
      margin-left: auto;
      margin-right: auto;
      position: relative;

      .buttonGroup {
        display: flex;
        justify-content: space-between;
        margin: rem(32) auto 0;
      }

      .button {
        flex: 50%;

        & + .button {
          margin-left: 10px;
        }
      }

      .errors {
        line-height: rem(15);
        font-size: rem(12);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        top: rem(235);
        width: 100%;

        .errorTitle {
          color: $text-red;
          margin-top: 0;
          margin-bottom: rem(15);
        }

        .errorRules {
          .rule {
            color: $text-yellow;
            margin: 0;

            &.satisfy {
              color: $text-green;
            }
          }
        }
      }
    }
  }
}
