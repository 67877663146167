@import '@/styles/variables';
@import '@/styles/functions';

.container {
  position: relative;

  .select {
    width: 100%;
    height: 100%;
    padding-left: rem(17);
    padding-right: rem(17);
    border: none;
    color: $text-gray-8d;
    font-size: rem(14);
    cursor: pointer;
    line-height: rem(18);
    height: rem(39);
    font-family: inherit;
    border-bottom: 1px solid $border-light-gray;
    background-color: $background-gray-f3;
    -moz-appearance: none;
    -webkit-appearance: none;

    &.error {
      border-bottom-color: #fa4d55;
    }

    &::after {
      content: '\e90c';
      position: absolute;
      top: 50%;
      right: rem(17);
      color: $text-black;
      transform: translateY(-50%);
    }

    &:focus {
      outline: none;
    }

    &::-ms-expand {
      display: none;
    }

    &:disabled {
      cursor: not-allowed;
      color: $text-gray-8d;
      opacity: 0.5;

      & + [class^='icon-'] {
        cursor: not-allowed;
        color: $text-gray-8d;
      }

      &.hasValue {
        color: $text-gray-8d;
      }
    }

    &.hasValue {
      color: $text-black;
    }
  }

  & [class^='icon-'] {
    position: absolute;
    top: 50%;
    right: rem(17);
    color: $text-black;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
