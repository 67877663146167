@import '@/styles/variables';

.container {
  max-height: 1000px;
  overflow: auto;
  .item {
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid $border-gray-e0;

    .content {
      .header {
        font-weight: $font-semi-bold;
        margin-bottom: 5px;
      }

      flex-grow: 8;
      flex-wrap: nowrap;
    }
    .controls {
      flex-grow: 1;
      text-align: right;

      .editControl {
        color: $text-blue;
      }

      .deleteControl {
        color: $text-red;
      }
    }
  }
}

.center {
  text-align: center;
}