@import '@/styles/functions';
@import '@/styles/variables';

.container {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100vh;
  transform: translateX(-50%);
  z-index: 999;
  display: none;
  overflow: auto;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: rem(16);
  padding-right: rem(16);
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index: 1000;

  &.open {
    display: flex;
  }

  .content {
    z-index: 1;
    width: 100%;
    overflow: hidden;
    min-height: rem(50);
    position: relative;
    background-color: #fff;
    box-shadow: 8px 8px 8px 0px rgba(0,0,0,0.16);

    &.sm {
      max-width: rem(375);
    }

    &.md {
      max-width: rem(600);
    }

    &.lg {
      max-width: rem(992);
    }

    .header {
      width: 100%;
      background: $background-blue;
      padding: rem(10);
      box-sizing: border-box;
      color: #fff;
    }
  }
}
