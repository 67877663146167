@import '@/styles/variables';
@import '@/styles/functions';

.container {
  .tools {
    display: inline-block;
    padding: 0;
    list-style: none;
    margin: 0;

    li {
      display: inline-block;

      &+li:before {
        padding: rem(5);
        content: "";
      }
    }

    .export {
      color: $text-blue;
    }

    .received {
      color: $text-green;
    }

    .refunded {
      color: $text-yellow;
    }

    .removed {
      color: $text-red;
    }

    .clear {
      color: $text-light-blue;
    }

    .isDisabled {
      color: currentColor;
      cursor: not-allowed;
      opacity: 0.5;
      text-decoration: none;
    }
  }
}