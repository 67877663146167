@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?lojoxi');
  src:  url('fonts/icomoon.eot?lojoxi#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?lojoxi') format('truetype'),
    url('fonts/icomoon.woff?lojoxi') format('woff'),
    url('fonts/icomoon.svg?lojoxi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-circle-success:before {
  content: "\e90f";
}
.icon-circle-info:before {
  content: "\e910";
}
.icon-circle-error:before {
  content: "\e911";
}
.icon-home:before {
  content: "\e90d";
}
.icon-map:before {
  content: "\e90e";
}
.icon-chevrons-right:before {
  content: "\e907";
}
.icon-chevrons-left:before {
  content: "\e908";
}
.icon-loader:before {
  content: "\e901";
}
.icon-plus:before {
  content: "\e900";
}
.icon-tree-structure:before {
  content: "\e902";
}
.icon-logout:before {
  content: "\e903";
}
.icon-chart:before {
  content: "\e904";
}
.icon-group-users:before {
  content: "\e905";
}
.icon-user-settings:before {
  content: "\e906";
}
.icon-esc-fat:before {
  content: "\e913";
}
.icon-esc:before {
  content: "\e912";
}
.icon-arrow-up:before {
  content: "\e909";
}
.icon-arrow-right:before {
  content: "\e90a";
}
.icon-arrow-left:before {
  content: "\e90b";
}
.icon-arrow-down:before {
  content: "\e90c";
}
.icon-warning:before {
  content: "\e914";
}
