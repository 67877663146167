@import '@/styles/variables';

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin stickContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: inherit;
  width: inherit;
}

@mixin overflowContent {
  flex: 1 1 auto;
  overflow: auto;
  height: 100%;
}

@mixin memberName {
  font-weight: bold;
  color: $text-blue;
  margin-bottom: 10px;
  text-decoration: underline;
  line-height: 1.5;
  font-size: rem(14);
}

@mixin action {
  color: $text-blue;
  cursor: pointer;
  text-decoration: underline;
}
