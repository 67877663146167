@import '@/styles/variables';
@import '@/styles/functions';

.container {
  margin-bottom: rem(10);

  .label {
    font-size: rem(14);
    line-height: rem(18);
    margin-top: 0;
    margin-bottom: rem(7);
    font-weight: $font-semi-bold;
    user-select: none;
  }

  .actions {
    display: flex;
    align-items: center;

    .checkBox {
      margin-bottom: 0;
      margin-left: rem(17);

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
