@import '@/styles/variables';
@import '@/styles/functions';

.container {
  width: 100%;

  .content {
    text-align: left;
    width: 100%;
    margin-bottom: rem(20);
    border-collapse: collapse;

    .input {
      margin: rem(10) 0;
      width: 100%;
      max-width: none;
    }
  }
}